@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.lexical h1 {
  @apply block font-sans text-4xl font-semibold leading-[1.3] tracking-normal text-inherit antialiased;
}
 
.lexical h2 {
  @apply block font-sans text-3xl font-semibold leading-snug tracking-normal text-inherit antialiased;
}
 
.lexical p {
  @apply block font-sans text-base font-normal leading-relaxed text-inherit antialiased;
}
 
.lexical ul {
  @apply list-inside list-disc;
}
 
.lexical ol {
  @apply list-inside list-decimal;
}
 
.lexical li {
  @apply font-sans text-base font-normal leading-relaxed text-inherit antialiased;
}
 
.lexical blockquote {
  @apply block font-sans text-xl font-normal leading-relaxed text-inherit antialiased;
}
 
.lexical code {
  @apply rounded-md bg-gray-900/[0.1] px-1 py-0.5 text-sm font-normal text-gray-900;
}

body {
  margin: 0;
  font-family: "Fira Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}